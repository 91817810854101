@import '../../../scss/components_base';
.blockContainer {
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;
}
.videoContainer {
  position: absolute;
  display: grid;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.aspectContainer {
  position: relative;
  width: 100%;
  height: 0;
}
.poster {
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: -1;
  grid-row-end: -1;
  // position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  transition: opacity 0.2s;
}

.posterHiding {
  opacity: 0;
}
.video {
  // background: red;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: -1;
  grid-row-end: -1;
}
