@import '../../scss/components_base';

.container {
  position: relative;
  width: 100%;
  text-align: left;
  min-height: 100vh;
  background: black;
  color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include md {
    padding-bottom: 0;
  }
}
.rows {
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 100px;
  margin-bottom: 42vh;
  @include md {
    margin-bottom: 0;
    margin-top: 0;
  }
}
.row {
  text-align: center;
  @include md {
    display: flex;
    white-space: nowrap;
  }
}
.rowItem {
  @include unstyled-link;
  display: block;
  margin-top: 40px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  opacity: 0.5;
  &:hover {
    color: white;
  }
  @include md {
    margin-top: 0px;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    opacity: 1;
  }
}

.rowItemSelected {
  opacity: 1;
}
.rowItemCategory {
  display: none;
  // display: block;
  @include md {
    @include h2-secondary;
    font-style: italic;
    display: inline;
  }
}
.rowItemCategoryMobile {
  @include h2-secondary;

  display: block;
  font-style: italic;
  font-size: 19px;
  @include md {
    display: none;
  }
}
.rowItemTitle {
  @include h2-secondary;
  font-size: 38px;
  line-height: 112%;
  display: block;
  @include md {
    @include h2-secondary;
    margin-right: rem(20);
    line-height: 120%;
    display: inline;
  }
}
.rowItemSeperator {
  @include h2-secondary;
  display: none;
  @include md {
    margin-left: rem(20);
    margin-right: rem(20);
    display: inline;
  }
}

.category {
  @include h2;
  text-align: center;
  margin-bottom: 25px;
  z-index: 1;
  margin-top: 180px;
  @include md {
    margin-top: 0;
    margin-bottom: rem(44);
  }
}
.followerContainer {
  display: block;
}
.link {
  @include unstyled-link;
}
