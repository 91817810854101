$breakpoint-sm: 420px;
$breakpoint-md: 1071px;
$breakpoint-lg: 1124px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1440px;
$breakpoint-xxxl: 1600px;

@mixin sm {
  @media (min-width: em($breakpoint-sm)) {
    @content;
  }
}
@mixin md {
  @media (min-width: em($breakpoint-md)) {
    @content;
  }
}
@mixin lg {
  @media (min-width: em($breakpoint-lg)) {
    @content;
  }
}
@mixin xl {
  @media (min-width: em($breakpoint-xl)) {
    @content;
  }
}
@mixin xxl {
  @media (min-width: em($breakpoint-xxl)) {
    @content;
  }
}
@mixin xxxl {
  @media (min-width: em($breakpoint-xxxl)) {
    @content;
  }
}
