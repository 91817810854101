@import '../../../scss/components_base';

.mobileImage,
.desktopImage,
.desktopImageCanSwap {
  line-height: 0;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  --image-transition-time: 0.4s;
  img {
    opacity: 0;
    transition: opacity var(--image-transition-time);
  }
}
.imageLoaded {
  img {
    opacity: 1;
  }
}
.desktopImageCanSwap {
  display: none !important;
}
@media (orientation: landscape) {
  .desktopImageCanSwap {
    display: block !important;
  }
  .mobileImage {
    display: none !important;
  }
}
