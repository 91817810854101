@use 'sass:math';

@mixin pill-button {
  @include body-large();
  display: block;
  text-align: center;
  height: rem(60);
  text-decoration: none;
  background: transparent;
  color: black;
  border: 1px solid black;
  border-radius: 33px;
  padding-left: rem(50);
  padding-right: rem(50);
  // body large has a 19px size
  padding-top: math.div(16px, 19px) * 1em;
  min-width: rem(180);
  &:hover {
    background: transparent;
    color: black;
  }
  &:active {
    background: transparent;
    color: black;
  }
  &:visited {
  }

  @include md {
    // background: white;
    &:hover {
      color: white;
      background: black;
    }
    &:active {
      color: white;
      background: black;
    }
  }
}

@mixin btn {
  cursor: pointer;
  text-decoration: none;
  border: 0;

  font-size: inherit;
  display: inline-block;
  text-align: left;

  background: none;
  color: inherit;
  margin: 0;
  padding: 0;

  &:disabled {
    cursor: default;
  }

  &:hover {
    background: none;
  }
}
