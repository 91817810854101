@import '../../scss/components_base';

.container {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: black;
  transition: background 1s;

  @include md {
    padding-bottom: 0;
  }
}

.imageContainer {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  @include md {
    width: rem(449);
    height: rem(614);
  }
}
