@use 'sass:math';

@mixin vw-font-size($px-size, $min-px-size: null, $max-px-size: null, $as-at: $design-width) {
  $unitless-vw-size: math.div($px-size, $as-at) * 100;
  $stop-shrinking-at: if($min-px-size, round(math.div($as-at * $min-px-size, $px-size)), null);
  $start-shrinking-at: if($max-px-size, round(math.div($as-at * $max-px-size, $px-size)), null);

  @if $stop-shrinking-at {
    @media (max-width: $stop-shrinking-at) {
      font-size: math.div($stop-shrinking-at * $unitless-vw-size, 100);
    }

    @if $start-shrinking-at {
      @media (min-width: #{$stop-shrinking-at + 1px}) and
             (max-width: #{$start-shrinking-at - 1px}) {
        font-size: #{$unitless-vw-size + 'vw'};
      }

      @media (min-width: $start-shrinking-at) {
        font-size: math.div($start-shrinking-at * $unitless-vw-size, 100);
      }
    } @else {
      @media (min-width: #{$stop-shrinking-at + 1}) {
        font-size: #{$unitless-vw-size + 'vw'};
      }
    }
  } @else if $start-shrinking-at {
    @media (max-width: #{$start-shrinking-at - 1}) {
      font-size: #{$unitless-vw-size + 'vw'};
    }

    @media (min-width: $start-shrinking-at) {
      font-size: $start-shrinking-at * $unitless-vw-size / 100;
    }
  } @else {
    font-size: $px-size;
  }
}

// h1, h2 etc mixins are useful when you need an h2 to look like an h3 or something.
@mixin body-small {
  font-family: var(--font-primary);
  font-size: 11px;
  line-height: 1.2;
  font-style: normal;
  font-weight: normal;
  @include md {
    font-size: rem(14);
    line-height: 1.35;
  }
}
@mixin body {
  font-family: var(--font-primary);
  font-size: 12px;
  line-height: 1.35;
  font-style: normal;
  font-weight: normal;
  @include md {
    font-size: rem(16);
  }
}
@mixin body-large {
  font-family: var(--font-primary);
  font-size: 14px;
  line-height: 1.24;
  font-style: normal;
  font-weight: normal;
  @include md {
    line-height: 1.35;
    font-size: rem(19);
  }
}
@mixin h1 {
  font-family: var(--font-primary);
  line-height: 1.2;

  font-size: 45px;
  @include md {
    font-size: rem(86.9886);
  }
}
@mixin h1-secondary {
  font-family: var(--font-secondary);
  font-weight: 300;
  line-height: 0.9;
  letter-spacing: -2px;
  font-style: normal;
  font-weight: normal;

  font-size: 80px;
  @include md {
    letter-spacing: -8px;
    line-height: 1.28;
    font-size: rem(250);
  }
}

@mixin h2 {
  // Your h2 styles here
  font-family: var(--font-primary);
  line-height: 1.35;
  font-style: normal;
  font-weight: normal;

  font-size: 24px;
  @include md {
    letter-spacing: -1px;
    font-size: rem(40);
  }
}
@mixin h2-secondary {
  // Your h2 styles here
  font-family: var(--font-secondary);
  font-weight: 300;
  line-height: 1.35;
  text-align: center;
  font-style: normal;
  font-weight: normal;

  font-size: 50px;
  @include md {
    line-height: 1.2;
    font-size: rem(60);
  }
}

@mixin h3 {
  // Your h3 styles here
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: normal;
  line-height: 1.35;
  font-size: 20px;
  @include md {
    letter-spacing: -1px;
    font-size: rem(34);
    line-height: 1.2;
  }
}
@mixin h3-secondary {
  // Your h3 styles here
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: 300;
  line-height: 1.2;
  font-size: 20px;
  @include md {
    line-height: 1.15;
    font-size: rem(40);
  }
}

@mixin h4 {
  // Your h4 styles here
  font-family: var(--font-primary);
  line-height: 1.25;
  font-weight: 400;

  font-size: 16px;
  @include md {
    font-size: rem(25);
  }
}
@mixin h4-secondary {
  // Your h4 styles here
  font-family: var(--font-secondary);
  font-weight: 300;
  line-height: 1.15;
  font-size: 16px;
  @include md {
    font-size: rem(25);
  }
}
