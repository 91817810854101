@import '../../scss/components_base';
.container {
  position: relative;
  width: 100%;
  color: black;
  padding-bottom: 30px;

  @include md {
    @include grid-standard;
    padding-bottom: 0px;
    min-height: 150vh;
    @media screen and (min-width: 2000px) {
      min-height: 110vh;
      // color: red;
    }
  }
}
.contentContainer {
  @include grid-standard;
  @include gutter;
  position: relative;
  z-index: 2;
  grid-column-start: 1;
  grid-column-end: -1;

  grid-template-areas:
    't t t t t t t t t t t t t t t t'
    'a a a a a a a c c c c c c c c c'
    'q q q q q q q q q q q q q q q q'
    'f f f f f f f f f f f f f f f f';

  @include md {
    margin-top: rem(50);
    grid-template-areas:
      'a a a . . . . . . . . . . c c c'
      't t t t t t t t t t t t t t t t'
      'q q q q q q q q q q q q q q q q'
      'f f f f f f f f f f f f f f f f';
    grid-template-rows: auto auto auto 1fr;
  }
}

.parallaxContainer {
  overflow: hidden;
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  grid-column-start: 1;
  grid-column-end: -1;
  grid-row-start: 1;
  grid-row-end: -1;
}
.parallaxRelative {
  position: relative;
  height: 100%;
  width: 100%;
}

.parallaxItem {
  position: absolute;
  left: 0;
  width: 140px;

  &:nth-child(1) {
    top: -200px;
    left: auto;
    right: var(--page-gutter);
  }
  &:nth-child(2) {
    display: none;
    top: -10%;
    left: var(--page-gutter);
  }
  &:nth-child(3) {
    top: 10%;
    left: auto;
    right: 10%;
  }
  &:nth-child(4) {
    top: 20%;
    left: 10vw;
  }
  &:nth-child(5) {
    top: -30%;
    left: 45vw;
  }
  &:nth-child(6) {
    top: 90%;
    left: 72vw;
  }
  &:nth-child(7) {
    top: 95%;
    left: 5vw;
  }

  @include md {
    width: 221px;
    &:nth-child(2) {
      display: block;
    }
  }
}
// .quote {
//   margin-bottom: rem(75);
//   @include fillGrid;
//   @include md {
//     grid-area: q;
//     margin-bottom: rem(275);
//   }
// }

.quoteTitle {
  @include h1-secondary;
  text-align: center;
  grid-area: t;
  margin-top: 100px;
  margin-bottom: 55px;
  @include md {
    margin-bottom: 0;
    margin-top: rem(188);
    align-self: flex-start;
  }
}
.quoteContainer {
  grid-area: q;
}
.quote {
  @include h3-secondary;
  text-align: center;
  margin-top: 50px;
  @include md {
    margin-top: 0px;
  }
}
.quoteName {
  @include h3-secondary;
  text-align: center;
  font-style: italic;
  margin-top: 20px;
  margin-bottom: 20px;
  @include md {
    margin-bottom: 0;
    margin-top: rem(35);
  }
}

.buttonApproach {
  @include pill-button;
  width: fit-content;
  grid-area: a;
  @include md {
    margin-left: rem(40);
  }
}
.buttonCulture {
  @include pill-button;
  width: fit-content;
  grid-area: c;
  justify-self: flex-end;
  @include md {
    margin-right: rem(40);
  }
}
.footer {
  grid-area: f;
  margin-top: 225px;
  // paddding
  @include md {
    margin-top: 0;
    padding-top: rem(75);
    padding-bottom: rem(75);
    align-self: flex-end;
  }
}
