@import '../../scss/components_base';
.main {
  position: relative;
  // background: black;

  :global .vjs-error-display {
    display: none;
  }
}
.container {
  @include gutter;
  z-index: 1;
  position: relative;
  color: white;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @include md {
    min-height: 100vh;
  }
}
.heroContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.videoPositioner {
  position: relative;
  // width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.videoFullWidth {
  width: 100%;
  height: auto;
  :global {
    .vjs-tech {
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      left: auto;
    }
  }
}
.darkener {
  width: 100%;
  height: 100%;
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  opacity: 0;
  transition: opacity 0.25s;
  @include md {
  }
}
.darkenerIsDark {
  opacity: 0.8;
}
.introContainer {
  flex: 0;
  max-width: rem(1263);
  align-self: center;
  margin-top: 60px;
  @include md {
    margin-top: 0px;
  }
}
.logoContainer {
  flex: 1;
  align-items: center;
  display: flex;
  align-self: center;
  width: 100%;
  max-width: rem(405);
}
