@import '../../scss/components_base';

.container {
  position: relative;
  height: 100%;
  width: 90vw;
  text-align: left;

  display: grid;
  grid-template-rows: 1fr auto;

  @include md {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    width: 40vw;
    padding-bottom: 0;
  }

  @include xxxl {
    width: 25vw;
  }
}

.imageContainer {
  z-index: 0;

  height: 100%;
  width: 100%;

  grid-row-start: 1;
  grid-column-start: 1;
  grid-column-end: -1;
  @include md {
    padding-bottom: 0;
    grid-row-end: -1;
  }

  video {
    object-fit: cover;
    pointer-events: none;
  }
}
.relativeParent {
  position: relative;
  height: 100%;
  width: 100%;
}
.imagePlaceholder {
  width: 100%;
}

.title {
  @include h4;
  letter-spacing: normal;
  opacity: 0;
  transition: opacity 0.5s;
  @include md {
    @include h4;
    transition: opacity 0;
    opacity: 1;
  }
}
.titleSpan {
  opacity: 0;
  transition: opacity 0.5s;
  @include md {
    opacity: 1;
  }
}
.titleInViewport {
  opacity: 1;
}
.copyContainer {
  @include body();
  padding-top: rem(28);
  padding-left: var(--page-gutter);
  grid-column-start: 1;
  grid-column-end: -1;
  grid-row-start: 2;
  position: relative;
  z-index: 1;
  @include md {
    grid-row-start: 1;
  }
}
