@import '../../scss/components_base';

.container {
  position: relative;
  z-index: 2;
  max-width: 100%;
  @include md {
    @include grid-standard;
    grid-template-areas: 'l l l l l . . e e e x x x s s s';
    max-width: auto;
  }
}
.tagLine {
  grid-area: l;

  margin-bottom: var(--space);
  @include md {
    margin-bottom: 0;
    max-width: 800px;
  }
}
.email {
  grid-area: e;
}
.address {
  grid-area: x;
}
.subscribe {
  grid-area: s;
}
.hideOnMobile {
  display: none;
  @include md {
    display: block;
  }
}
