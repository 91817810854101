@mixin link-primary {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: rem(10);
  color: var(--color-white);
  text-decoration-color: var(--color-secondary);
  &:hover {
    text-decoration-color: var(--color-white);
  }
  &:active {
    text-decoration-color: var(--color-secondary);
  }
}
@mixin link-secondary {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: rem(10);
  color: var(--color-white);
  text-decoration-color: var(--color-primary);
  &:hover {
    text-decoration-color: var(--color-primary-30);
  }
  &:active {
    text-decoration-color: var(--color-primary-100);
  }
}
@mixin unstyled-link() {
  text-decoration: none;
  color: inherit;
  font-family: inherit;
  &:hover {
    color: var(--color-rollover);
  }
}
