@import '../scss/components_base';

.carouselContainer {
  background-color: white;
  color: black;
  width: 100%;
  height: 75vh;
  z-index: 2;
  position: relative;
  @include md {
    background-color: black;
    color: white;
    height: 55vh;
  }
}
.footerContainer {
  background: white;
  padding-top: rem(50);
  position: relative;
  z-index: 1;
  @include md {
    padding-top: 0;
  }
}
.heroIsHidden {
  visibility: hidden;
}
