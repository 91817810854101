@import '../../scss/components_base';

.row {
  position: relative;
  display: block;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}
.wrap {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.slider {
  display: flex;
}
